//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import LanguageSwitch from '../LanguageSwitch.vue';
export default {
    name: 'NavigationMeta',
    components: {LanguageSwitch},
    computed: {
        ...mapGetters(['getStoredPage', 'getStoredSiteProperties']),
    },
    data() {
        return {
            meta: null,
            showLink: null,
            userData: null,
        };
    },
    created() {
        try {
            this.meta = this.getStoredSiteProperties.meta.navigation;
        } catch (e) {
            console.error(e);
        }
        /*
            this.$nextTick(() => {
                document.documentElement.style.setProperty('--navmeta-height', `${this.$el.getBoundingClientRect().height}px`);
            });
            */
    },
    async mounted() {
        const accessToken = window.localStorage.getItem('access_token');
        if (!accessToken) {
            this.showLink = 'account';
        } else {
            this.userData = await this.$nuxt.context.app.api.User.userData();
            this.showLink = this.userData.isLoggedIn ? 'login' : 'account';
        }
        this.meta = this.meta.filter((entry) => entry?.link?.title !== this.showLink);
    },
};
