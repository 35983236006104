import { render, staticRenderFns } from "./NavigationMeta.vue?vue&type=template&id=6d03d7eb&"
import script from "./NavigationMeta.vue?vue&type=script&lang=js&"
export * from "./NavigationMeta.vue?vue&type=script&lang=js&"
import style0 from "./NavigationMeta.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSwitch: require('/usr/src/app/components/LanguageSwitch.vue').default})
