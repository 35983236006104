//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SubmenuLink',
    props: ['id', 'children', 'open', 'active', 'isExternalLink', 'href'],
    data() {
        return {
            target: null,
        }
    },
    methods: {
        handleSubFlyout(event) {
            if (this.isExternalLink) {
                return true;
            }
            event.preventDefault();
            if (this.children) {
                this.$nuxt.$emit('submenu.flyout.toggle', {toggle: this.id});
            } else {
                window.location = this.$el.href;
            }
        }
    },
    mounted() {
        if (this.isExternalLink) {
            this.target = '_blank';
            return;
        }
    }
};
