import axios from "axios";
import Cache from '../services/cache';

export default async ({app, $config}, inject) => {
    const setAppTranslations = (items) => {
        let values = {};
        for (const lang in items) {
            values = items[lang];
            app.i18n.setLocaleMessage(lang, values);
        }
    };

    try {
        const preview = typeof app.context.query.preview !== 'undefined' && app.context.query.preview === '1';
        const cache = new Cache(preview === true ? false : parseInt($config.cache, 10) === 1);

        const cached = cache.getTranslations();
        if (cached) {
            return setAppTranslations(cached);
        }

        const res = await axios.get($config.pimcoreUrl + '/translations');
        setAppTranslations(res.data);
        cache.setTranslations(res.data);
    } catch (e) {
        console.error(e);
    }
}
