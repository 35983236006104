//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";

export default {
    name: 'NavigationSearch',
    props: ['mobile'],
    data() {
        return {
            searchTerm: '',
            oxomiRoute: '',
            suggestTimeout: null,
            oxomiResults: [],
            results: {},
        };
    },
    mixins: [require('./../../mixins/product_range.js').default],
    computed: {
        linkRows() {
            return this.$store.state?.siteProperties?.meta?.search?.filter((o) => !!o?.children) ?? [];
        },
    },
    async fetch() {
        try {
            let data = (await this.$nuxt.context.app.api.website.getLocalizedPageSlugs(this.$store.state.siteProperties.oxomi_page.id))[this.$i18n.locale];
            const slug = [];
            while (data.parent) {
                if (data.slug !== '/') {
                    slug.push(data.slug);
                }
                data = data.parent;
            }

            this.oxomiRoute = `/${slug.reverse().join('/')}`;
        } catch (e) {
            console.error(e);
        }
    },
    methods: {
        getLink(page) {
            return this.$localeSlug(page);
        },
        openDocument(item) {
            window.oxomi.openCatalog({"catalog": item.id.toString()});
        },
        search() {
            if (this.searchTerm.length) {
                axios.get(`${this.$config.pimcoreUrl}/${this.$i18n.locale}/search_suggestions`, {params: {query: this.searchTerm.trim(), product_range: this.getSelectedProductRange()}}).then((res) => {
                    this.results = res.data;
                }).catch((e) => {
                    //alert('An error occurred please try again later.');
                });

                const bodyFormData = new FormData();
                bodyFormData.set('topBrands', 'true');
                bodyFormData.set('filterLang', this.$i18n.locale);
                bodyFormData.set('filterBox', '#universal-search-filter');
                bodyFormData.set('filterBoxGroups', 'types,tags,langs');
                bodyFormData.set('currentUrl', '');
                bodyFormData.set('portal', '2024579');
                bodyFormData.set('user', '');
                bodyFormData.set('roles', '');
                bodyFormData.set('accessToken', '');
                bodyFormData.set('lang', this.$i18n.locale);

                axios.post('https://oxomi.com/service/json/portal/search', bodyFormData)
                .then((res) => {
                    this.oxomiResults = [];
                    res.data.groups.forEach((data) => {
                        if (data.catalogs) {
                            this.oxomiResults = this.oxomiResults.concat(data.catalogs.filter(item => item.name.toLowerCase().includes(this.searchTerm.trim().toLowerCase())));
                        }
                    });
                })
                .catch((error) => {
                    console.log(error)
                })
            } else {
                this.results = {};
            }
        },
        searchSuggest() {
            clearTimeout(this.suggestTimeout);
            this.suggestTimeout = setTimeout(this.search, 700);
        }
    }
};
