//
//
//
//
//

import MainComponent from '../components/MainComponent.vue';

export default {
    components: {MainComponent},
}
