//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Companylogo from '../Companylogo.vue';
import NavigationSubmenu from './NavigationSubmenu.vue';
import NavigationUtils from './NavigationUtils.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'NavigationMain',
    components: {NavigationUtils, NavigationSubmenu, Companylogo},
    computed: {
        ...mapGetters([
          'getStoredMenu'
        ]),
    },
    data() {
        return {
            opened: null,
            header: null,
        }
    },
    watch: {
        opened(val) {
            if (val !== null) {
                this.$nuxt.$emit('navigation.state', {navOpen: true});
            } else {
                this.$nuxt.$emit('navigation.state', {navOpen: false});
            }
            return val;
        }
    },
    mounted() {
        this.header = document.body.querySelector('header.header');

        // toggle "open"-state in navigation main links
        this.$nuxt.$on('submenu.closed', ({id}) => {
            if (this.opened === id) {
                this.opened = null;
            }
        });

        this.$nuxt.$on('search.toggle', ({event}) => {
            this.handleSubmenu(event)
        });
    },
    methods: {
        handleSubmenu(event) {
            this.opened = event.target.getAttribute('id');
            this.$nuxt.$emit('submenu.toggle', {toggle: event.target.parentNode.querySelector('.submenu')});
        },
        slug(id) {
            return this.$getLocalizedSlug(id);
        }
    }
};
