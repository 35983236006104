//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavigationTeaser from './NavigationTeaser.vue';
import NavigationImageTeaser from './NavigationImageTeaser.vue';
import NavigationLinklist from './NavigationLinklist.vue';

export default {
    name: 'NavigationSubmenuFlyout',
    components: {NavigationLinklist, NavigationImageTeaser, NavigationTeaser},
    props: ['id', 'page', 'pages', 'dark', 'expanded'],
    computed: {
        hasShowAllLinks() {
            return this.page.flyout && this.page.flyout.length && this.page.flyout[0].link.length;
        }
    },
    watch: {
        expanded(val) {
            if (val) {
                this.handleScrollcontainer();
            }
        }
    },
    data() {
        return {
            show: false,
        };
    },
    mounted() {
        this.$nuxt.$on('submenu.flyout.toggle', ({toggle}) => {
            if (this.expanded) {
                this.show = (toggle === this.id);
            } else {
                if (toggle === this.id) {
                    this.show = !this.show;
                } else {
                    this.show = false;
                }
            }
            if (this.show) {
                this.handleScrollcontainer();
            }
        });
        if (this.id === 0) {
            this.show = true;
            this.handleScrollcontainer();
        }
    },
    methods: {
        handleScrollcontainer() {
            this.$forceUpdate();
            this.$nextTick(() => {
                const sc = this.$refs.scrollcontainer;
                if (sc) {
                    sc.style.maxHeight = `calc(100vh - calc(${sc.getBoundingClientRect().top}px))`;
                }
            });
        },
        subLinksVisible(subPages) {
            const subPageHiddenValues = subPages.map(subPages => subPages.hidden)
            return (subPageHiddenValues.includes(null) || subPageHiddenValues.includes(false));
        }
    }
};
