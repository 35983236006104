/**
 * The product range only determines whether a product should be displayed.
 * Furthermore, only the corresponding price should be taken from the mapped product localisation.
 */
const COOKIE_LIFETIME = 60 * 60 * 24 * 7; // 7 days
const PRODUCT_RANGES = {
    "PT": "EN", // International
    "DE": "DE",
    "GB": "EN",
    "FR": "FR",
    "NL": "NL",
    "IT": "IT",
    "PL": "EN",
    "CZ": "EN",
    "ES": "ES",
    "CN": "CN",
    "BE": "NL"
};

export default {
    methods: {
        getSelectedProductRange: function () {
            let browserLang = this.$store.getters.browserLanguage;
            const currentProductRange = this.$cookies?.get('product_range');
            browserLang = browserLang === 'EN' ? 'GB' : browserLang;
            if (currentProductRange) {
                return currentProductRange.toUpperCase();
            } else if (browserLang in PRODUCT_RANGES) {
                return browserLang;
            }
            return 'PT';
        },
        setSelectedProductRange: function (productRange, configuratorProductPage = null, isConfigurator = false) {
            this.$cookies.set('product_range', productRange, {
                path: '/',
                maxAge: COOKIE_LIFETIME
            });
            if(isConfigurator && configuratorProductPage) {
                window.location = configuratorProductPage
            } else {
                window.location.reload();
            }
        },
        getCountryFromCurrentProductRange: function () {
            const activeProductRange = this.getSelectedProductRange().toUpperCase();
            return PRODUCT_RANGES[activeProductRange];
        },
        getProductRanges: function () {
            const activeProductRange = this.getSelectedProductRange();
            return Object.keys(PRODUCT_RANGES).map((countryCode) => {
                return {
                    code: countryCode,
                    active: countryCode.toUpperCase() === activeProductRange.toUpperCase(),
                }
            });
        },
        isInProductRange: function (product, productRange = null) {
            if (typeof product.negativeTerritories === 'undefined') {
                return false;
            }
            const activeProductRange = productRange ?? this.getSelectedProductRange();

            if (product.negativeTerritories.length === 0 && (activeProductRange.toUpperCase() === 'PT' || activeProductRange.toUpperCase() === 'RU')) {
                return true;
            }

            return !product.negativeTerritories.find((territory) => territory.toUpperCase() === activeProductRange.toUpperCase());
        }
    }
}
