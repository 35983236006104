//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'NavigationSubmenuFlyout',
    props: ['id', 'page', 'pages', 'dark', 'expanded', 'level'],
    data() {
        return {
            show: false,
        };
    },
    watch: {
        show() {
            if (this.show) {
                this.$nuxt.$emit('mobile-nav.level', {level: 3});
            } else {
                this.$nuxt.$emit('mobile-nav.level', {level: 2});
            }
        }
    },
    mounted() {
        if (this.level) {
            this.$nuxt.$on('submenu.flyout.toggle', ({toggle}) => {
                if (!this.expanded) {
                    if (toggle === this.id) {
                        this.show = !this.show;
                    } else {
                        this.show = false;
                    }
                }
            });
            if (this.id === 0) {
                this.show = true;
            }
            this.$nuxt.$on('mobile-nav.close', () => {
                this.show = false;
            });
        }
    },
    methods: {
        handleScrollcontainer() {
            this.$forceUpdate();
            this.$nextTick(() => {
                const sc = this.$refs.scrollcontainer;
                if (sc) {
                    sc.style.maxHeight = `calc(100vh - calc(${sc.getBoundingClientRect().top}px + 100px))`;
                }
            });
        }
    }
};
