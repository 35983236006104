//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    name: 'LanguageSwitch',
    components: { Multiselect },
    mixins: [require('../mixins/product_range.js').default],
    computed: {
        ...mapGetters(['getPageSlugs']),
        productRange() {
            return this.getSelectedProductRange().toLowerCase();
        },
    },
    data() {
        return {
            selected: null,
            options: null,
            isOpen: false,
            disabled: true,
        };
    },
    created() {
        if (!this.getPageSlugs) {
            return;
        }

        try {
            // map selectable options (languages)
            const options = Object.keys(this.getPageSlugs).map((key) => {
                return {
                    label: this.$i18n.t(`languageswitch.label.${key}`),
                    code: key,
                    slug: this.getPageSlugs[key],
                };
            });

            if (options) {
                this.options = options.sort((a, b) => a?.label?.localeCompare(b?.label) ?? 0);
                this.disabled = options.length <= 1;

                // set active locale
                this.selected = this.options.filter((item) => {
                    return item.code === this.$i18n.locale;
                });
            }
        } catch (e) {
            console.error(e);
        }
    },
    methods: {
        // handle selection
        onChange(option) {
            if (option) {
                this.$cookies.set('base_language', option.code, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 30,
                });
                const { slug } = option;
                if (slug) {
                    const urlParams = window.location.href.split('?')?.[1]
                    window.location = slug + `${urlParams ? '?' +  urlParams : '' }`;
                }
            }
        },
        onOpen() {
            this.isOpen = true;
        },
        onClose() {
            this.isOpen = false;
        },
    },
};
