//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavigationSubmenuFlyout from './NavigationSubmenuFlyout.vue';
import SubmenuLink from './SubmenuLink.vue';

export default {
    name: 'NavigationSubmenu',
    components: {SubmenuLink, NavigationSubmenuFlyout},
    props: {
        subpages: {
            default: () => {
                return [];
            }
        },
        headline: {
            default: null
        },
        flyout: {
            default: null
        },
        page: {
            default: null
        },
    },
    data() {
        return {
            expanded: false,
            subFlyoutId: 0,
            bs: null,
        };
    },
    watch: {
      expanded(val) {
          if (val) {
              window.requestAnimationFrame(() => {
                this.bs(false);
              });
          } else {
              this.bs(true);
              this.$nuxt.$emit('submenu.closed', {id: this.page?.id || 'search'});
              this.$nuxt.$emit('submenu.flyout.toggle', {toggle: 0});
          }
          return val;
      },
      // TODO: obsolete?
      subFlyoutId(val) {
          return val;
      }
    },
    created() {
        (async () => {
            if (process.client) {
                const { bodyScroll } = await import('../../assets/js/preventBodyScroll.js');
                this.bs = bodyScroll;
                if (this.subpages) {
                    this.$nuxt.$on('submenu.toggle', ({toggle}) => {
                        if (!this.expanded) {
                            this.expanded = (toggle === this.$el) || false;
                        } else {
                            this.expanded = false;
                        }
                    });
                }
            }
        })();
    },
    mounted() {
        // handle submenu-sub-flyout
        this.$nuxt.$on('submenu.flyout.toggle', ({toggle}) => {
            if (this.subFlyoutId !== toggle) {
                this.subFlyoutId = (this.subpages.filter((item) => item.id === toggle).length) ? toggle : 0;
            } else {
                this.subFlyoutId = 0;
            }
        });
        // init
    },
    methods: {
        closeSubFlyout() {
            this.$nuxt.$emit('submenu.flyout.toggle', {toggle: 0});
        },
        subLinksVisible(subPages) {
            const subPageHiddenValues = subPages.map(subPages => subPages.hidden)
            return (subPageHiddenValues.includes(null) || subPageHiddenValues.includes(false));
        }
    },
};
