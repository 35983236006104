//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';

export default {
    name: 'NavigationUtils',
    computed: {
        ...mapGetters([
            'getStoredSiteProperties',
            'getStoredPage'
        ]),
    },
    data() {
        return {
            animate: false,
            utils: null,
        };
    },
    watch: {
        '$store.getters.getWatchList'() {
            this.animate = true;
            setTimeout(() => {
                this.animate = false;
            }, 500);
        }
    },
    methods: {
        openSearch(event) {
            this.$nuxt.$emit('search.toggle', {event: event});
            this.$nextTick(() => {
                this.$refs.searchMenu.querySelector('input').focus();
            });
        }
    },
    created() {
        try {
            this.utils = this.getStoredSiteProperties?.meta?.utils;
        } catch (e) {
            console.error(this.utils);
        }
    }
};
